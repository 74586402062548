import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { RootRedirectorService } from '@app/services';

const routes: Routes = [
  {
    path: '',
    canActivate: [ RootRedirectorService ],
    loadChildren: () => import('./launcher/launcher.module').then(m => m.LauncherPageModule)
  },
  {
    path: 'preferment-calc',
    loadChildren: () => import('./preferment-calc/preferment-calc.module').then(m => m.PrefermentCalcPageModule)
  },
  {
    path: 'preferment-list',
    loadChildren: () => import('./preferment-list/preferment-list.module').then(m => m.PrefermentListPageModule)
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {
}
