import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';

import { NavController, Platform } from '@ionic/angular';

import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RootRedirectorService implements CanActivate {
  constructor(private readonly nav: NavController,
              private readonly platform: Platform) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.platform.is('pwa') || this.platform.is('capacitor')) {
      this.nav.navigateRoot([ 'preferment-calc' ], { animated: false }).then();
      return false;
    }

    return true;
  }
}
