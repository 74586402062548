import { Injectable } from '@angular/core';

import { Plugins } from '@capacitor/core';

import { from, Observable, OperatorFunction, ReplaySubject } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { prefermentStoreKey, StoredFormula } from './preferment-store.types';
import { IdGeneratorService } from '../id-generator/id-generator.service';
import { PrefermentFormFormula } from '@app/preferment-calc';

const { Storage } = Plugins;

@Injectable({ providedIn: 'root' })
export class PrefermentStoreService {
  private list$ = new ReplaySubject<StoredFormula[]>(1);
  private size$ = this.list$.pipe(map(list => list.length));

  constructor(private readonly idGenerator: IdGeneratorService) {
    this.load();
  }

  get list(): Observable<StoredFormula[]> {
    return this.list$.asObservable();
  }

  get size(): Observable<number> {
    return this.size$;
  }

  add = (name: string, formula: PrefermentFormFormula) =>
    this.list$
      .pipe(this.modifyList(list => list.concat([ { name, formula, id: this.nextId() } ])))
      .subscribe(list => this.list$.next(list));

  remove = (formula: StoredFormula) =>
    this.list$
      .pipe(this.modifyList(list => list.filter(item => item !== formula)))
      .subscribe(list => this.list$.next(list));

  private nextId = () => this.idGenerator.next();

  private load = () =>
    from(Storage.get({ key: prefermentStoreKey }))
      .pipe(map(response => typeof response.value === 'string' ? JSON.parse(response.value) : []))
      .subscribe(response => this.list$.next(response));

  private modifyList =
    <T, R extends Array<StoredFormula>>(project: (value: T, index: number) => R, thisArg?: any): OperatorFunction<T, R> =>
      (source: Observable<T>) =>
        source.pipe(
          take(1),
          map(project),
          map(list => list.sort((a, b) => a.name.localeCompare(b.name))),
          tap(list => Storage.set({ key: prefermentStoreKey, value: JSON.stringify(list) })));
}
